<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <div class="row align-items-center">
            <div class="col-3">
              <CHeaderNav>
                <CHeaderNavItem class="px-3">
                  <button
                    class="c-header-nav-btn"
                    @click="openSidebarEvent('contentFilter')"
                  >
                    <CIcon
                      v-if="!loading"
                      size="lg"
                      name="cil-filter"
                      class="mr-2"
                    />
                  </button>
                </CHeaderNavItem>
              </CHeaderNav>
            </div>
            <div class="col-9 text-right">
              <CButton color="success" square size="sm"
                @click="openModalEvent('create','contentMainForm', null, 'Yeni İçerik Ekle')"
              >
                Yeni Kayıt Ekle
              </CButton>
            </div>
          </div>
        </CCardHeader>
      
        <CCardBody>
          <CDataTable
            :items="contentMains"
            :fields="fields"
            :items-per-page="10"
            hover
            pagination
            :loading="loading"
            clickable-rows
            @row-clicked="rowClicked"
            @row-double-clicked="openModalEvent('update','contentMainForm', lastItem, 'İçerik Güncelleme Ekranı ('+ lastItem.name+')')"
            v-on:refresh="filterContents(filterParams)"
          >
            <template #show_details="{item}">
              <td class="py-2">
                <CDropdown
                  toggler-text="İşlemler"
                  class="m-2"
                  color="info"
                  size="sm"
                >
                  <CDropdownItem
                    @click="openModalEvent('update','contentMainForm', item, 'İçerik Güncelleme Ekranı ('+ item.name+')')">
                    Düzenle
                  </CDropdownItem>
                  <CDropdownItem
                    @click="openModalEvent('contents','contentForm', {contentMain: item}, 'İçerik Ekranı ('+ item.name+')')">
                    İçerik İşlemleri
                  </CDropdownItem>
                  <CDropdownItem @click="changeStatus(item)">
                      {{ item.activeFlag ? 'Pasif Et' : 'Aktif Et' }}
                  </CDropdownItem>
                  <CDropdownItem @click="openModalEvent('delete','confirm', item, 'İçerik Silme Ekranı ('+ item.name+')', item.name+' isimli içeriği silmek üzeresiniz bunu yapmak istediğinizden emin misiniz?')">
                    Kaydı Sil
                  </CDropdownItem>
                </CDropdown>
              </td>
            </template>

            <template #activeFlag="{item}">
              <td>
                <CIcon v-if="item.activeFlag" class="green" name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>

            <template #domesticFlag="{item}">
              <td>
                <CIcon v-if="item.domesticFlag" class="green" name="cil-check-alt"/>
                <CIcon v-else name="cil-x" class="red"/>
              </td>
            </template>
          
            <template #name="{item}">
              <td>
                <p  v-c-tooltip.mouseenter="{content: item.name}" style="width: 70px;overflow: hidden;white-space: nowrap;text-overflow:ellipsis;">
                  {{ item.name }}
                </p>
              </td>
            </template>

            <template #contentTypeName="{item}">
              <td>
                <CBadge :color="colors[item.contentTypeId % colors.length]"> {{ item.contentTypeName}}</CBadge>
              </td>
            </template>

            <template #createTime="{item}">
              <td>
                {{ dateFormat(item.createTime) }}
              </td>
            </template>

            <template #publishType="{item}">
              <td>
                <CBadge v-if=" getPublishType(item.publishType) && Object.keys(getPublishType(item.publishType)).length > 0" 
                  :color="colors[ getPublishType(item.publishType).displayOrder  % colors.length]"> 
                  {{ getPublishType(item.publishType).friendlyType }}
                </CBadge>
              </td>
            </template>

            <template #sendStartTime="{item}">
              <td>
                {{ item.sendStartTime &&  dateTimeFormat(item.sendStartTime ) }}
              </td>
            </template>

            <template #sendFinishTime="{item}">
              <td>
                {{ item.sendFinishTime &&  dateTimeFormat(item.sendFinishTime ) }}
              </td>
            </template>

          </CDataTable>
        </CCardBody>
      </CCard>
    </CCol>
    <ConfirmModal
      v-if="form == 'confirm'" 
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :confirm="crud"
      :desc="modalDesc"
      :data="modalProps"
      :actionType="actionType"
      :loading="loading"
      :size="size"
    />
    <FormModal 
      v-else
      :show="openModal" 
      :title="modalTitle" 
      :openModalEvent="openModalEvent"
      :operationEvent="crud"
      :actionType="actionType"
      :module="module"
      :form="form"
      :data="modalProps"
      :noFooter="noFooter"
      ref="form"
    />
    <FilterSidebar 
      :show="openSidebar" 
      :openSidebarEvent="openSidebarEvent"
      :operationEvent="filterContents"
      :module="module"
      :form="filterForm"
      :data="filterParams"
      :loading="loading"
    />
  </CRow>
</template>
<script>

import FormModal from '../components/formModal'
import ConfirmModal from '../components/confirmModal'
import FilterSidebar from '../components/filterSidebar'
import { prepareFilterParams } from "@/helpers/custom"
import moment from 'moment'

export default {
  name: 'Content',
  components: {
     ConfirmModal, FormModal, FilterSidebar
  },
  data() {
    return {
      webDomain: process.env.VUE_APP_SERVERINFO_WEBDOMAIN,
      modalTitle: '',
      actionType: '',
      module: 'content',
      form: 'contentForm',
      modalProps: {},
      modalDesc: '',
      openModal: false,
      openModalConfirm: false,
      lastPage: {},
      openSidebar: false,
      filterForm: 'contentFilter',
      filterParams: {},
      lastItem: {},
      size: 'sm',
      noFooter: false,
      colors: ['primary', 'success', 'warning', 'danger', 'info', 'dark'],
      fields: [
        {key: 'show_details', label: 'İşlemler', _style: 'font-size:12px'},
        {key: 'createTime', label: 'Oluşturma Tarihi', _style: 'font-size:12px'},
        {key: 'sendStartTime', label: 'Başlangıç Tarihi', _style: 'font-size:12px'},
        {key: 'sendFinishTime', label: 'Bitiş Tarihi', _style: 'font-size:12px'},
        {key: 'priority', label: 'Sıra', _style: 'font-size:12px'},
        {key: 'name', label: 'İsim', _style: 'font-size:12px'},
        {key: 'activeFlag', label: 'Aktif', _style: 'font-size:12px'},
        {key: 'publishType', label: 'Lokasyon ', _style: 'font-size:12px'},
        {key: 'contentTypeName', label: 'İçerik Tipi', _style: 'font-size:12px'},
      ],
      activePage: 1,
      pages: 0,
      filters: [{
        type: 'numeric',
        dataIndex: 'id',
        field: 'id'
      }, {
        type: 'numeric',
        dataIndex: 'contentType',
        field: 'contentTypeId',
        comparison: 'eq'
      },  {
        type: 'string',
        dataIndex: 'name',
        field: 'name'
      }, {
        type: 'boolean',
        dataIndex: 'activeFlag',
        field: 'activeFlag',
      },{
        type: 'string',
        dataIndex: 'publishType',
        field: 'publishType',
        comparison: 'eq'
      }, {
        type: 'date',
        dataIndex: 'startTime',
        field: 'createTime',
        comparison: 'gt'
      }, {
        type: 'date',
        dataIndex: 'endTime',
        field: 'createTime',
        comparison: 'lt',
      }
    ]
    }
  },
  computed: {
    contentMains(){
      return this.$store.getters.contentMains;
    },
    publishTypes(){
      return this.$store.getters.contentPublishTypes;
    },
    loading(){
      return this.$store.getters.contentLoading;
    }
  },
  methods: {
    getPublishType(data){

      let publishType = {};
      if(this.publishTypes.length > 0){
        publishType = this.publishTypes.find(e => e.type == data) 
      }

      return publishType
    },
    async openModalEvent(actionType, form, data, title, desc){
      this.confirmSize = 'sm'
      this.noFooter = false
      if(form == 'contentForm'){
        let params = {
          contentMainId: data.contentMain.id
        }
        this.noFooter = true


        await this.$store.dispatch('content_list', params)
      
      }else if( form == 'contentImageForm'){
        data.image = {}
        let params = {
          contentId: data.content.id
        }

        await this.$store.dispatch('content_images', params)
      }

      this.openModal = !this.openModal
      this.actionType = actionType
      this.form = form
      this.modalProps = data
      this.modalTitle = title
      this.modalDesc = desc
    },


    openSidebarEvent(form){
      this.openSidebar = !this.openSidebar
      this.filterForm = form
    },

    async filterContents(params) {
      this.filterParams = params
      let formData = prepareFilterParams(params, this.filters)
      
      await this.$store.dispatch('content_main_list', formData)
      
      if(this.openSidebar){
        await this.openSidebarEvent()
      }
    },

    changeStatus(item){
      let msg
      if(item.activeFlag)
        msg = 'İçerik durumunu <b> PASİF </b> etmek üzeresiniz bu işlemi yapmak istediğinize emin misiniz?'
      else
        msg = 'İçerik durumunu <b> AKTİF </b> etmek üzeresiniz bu işlemi yapmak istediğinize emin misiniz?'

      this.openModalEvent('status', 'confirm', item, 'İçerik Durum Güncelleme Ekranı ('+ item.name+')', msg )
    },

    rowClicked(item) {
      this.lastItem = item;
    },

    dateFormat(date){
      return moment(date).format("DD.MM.YYYY")
    },

    dateTimeFormat(date){
      return moment(date).format("DD.MM.YYYY HH:mm")
    },

    async contentTypes() {
      await this.$store.dispatch('contentType_list')
    },

    async contentPublishTypes() {
      await this.$store.dispatch('contentPublishType_list')
    },

    async langs() {
      await this.$store.dispatch('lang_list')
    },

    async crud(item, actionType) {

      var formData = new FormData();

      if(actionType == 'status'){
        let data = {
          id : item.id,
          activeFlag: !item.activeFlag
        }
       await this.$store.dispatch('content_changeActivity', data)
      
      } else if (this.form == 'contentForm') { 

        formData.append('actionType', item.id ? 'update' : 'create')
        item.title && formData.append('title', item.title )
        item.text && formData.append('text', item.text )
        formData.append('langId', item.langId)
        formData.append('mobileFlag', item.mobileFlag ? true : false)
        formData.append('videoFlag', item.videoFlag ? true : false)
        formData.append('contentMainId', this.modalProps.contentMain.id)

        if(item.targetURL){
          if(item.targetURL.startsWith('/en'))
            formData.append('targetURL', item.targetURL.substring(3))
          else
            formData.append('targetURL', item.targetURL)
        }


        if(item.id){
          formData.append('id',item.id ? item.id : '')
        }

        await this.$store.dispatch('content_action', formData)

        if(this.$store.getters.contentStatus.success) {

          let params = {
            contentMainId: this.modalProps.contentMain.id
          }

          await this.$store.dispatch('content_list', params)

          this.modalProps = {contentMain: this.lastItem}

        }

        return;

      } else {

        formData.append('name',item.name)
        formData.append('id',item.id ? item.id : '')
        formData.append('actionType', actionType)
        formData.append('priority',item.priority ? item.priority : 1)
        formData.append('contentTypeId',item.contentTypeId)
        formData.append('publishType', item.publishType)
        item.sendStartTime && formData.append('sendStartTime', moment(item.sendStartTime).format('YYYY-MM-DD HH:mm') )
        item.sendFinishTime && formData.append('sendFinishTime', moment(item.sendFinishTime).format('YYYY-MM-DD HH:mm') )


        await this.$store.dispatch('content_main_action', formData)

      }

      if(this.$store.getters.contentStatus.success) {
          await this.filterContents(this.filterParams);
          this.openModalEvent()
      }
    }
  },
  created() {
    this.filterContents(this.filterParams);
    this.langs();
    this.contentTypes();
    this.contentPublishTypes();
  }
}
</script>
